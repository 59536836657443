import React from 'react';
import './App.css';
import Login from "./Login";
import RaceDetail from "./RaceDetail";
import * as firebase from 'firebase';
import Menu from "./Menu";
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import Profile from "./Profile";
import Home from "./Home";
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
// import './js/clean-blog.min.js';

interface IProps extends RouteComponentProps {
}

interface iState {
    isLoggedIn: boolean
    selectedRace: string
}

class App extends React.Component<IProps, iState> {

    constructor(props: IProps) {
        super(props);

        let self = this;
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                self.setState({isLoggedIn: true})
            } else {
                self.setState({isLoggedIn: false})
            }
        });

        this.state = {isLoggedIn: false, selectedRace: ""};
        this.signedIn = this.signedIn.bind(this);
        this.logout = this.logout.bind(this);
    }

    logout() {
        this.props.history.push('/');
        firebase.auth().signOut();
    }

    signedIn() {
        if (this.props.location.pathname === '/login') {
            this.props.history.push('/profile');
        }
    }

    render() {
        return <div>
            <Menu logout={this.logout} isLoggedIn={this.state.isLoggedIn}  />

            <Route exact path="/" component={Home}/>

            <Route path="/race/:id" render={({match}) => (
                <RaceDetail raceId={match.params.id} isLoggedIn={this.state.isLoggedIn} />
            )} />

            <Route exact path="/profile" component={Profile}/>

            <Route exact path="/login" render={({match}) => (
                <Login signedIn={this.signedIn}/>
            )} />
        </div>
    }
}

export default withRouter(App);
