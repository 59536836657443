import React from 'react';
import firebase from 'firebase';
import {Table} from "react-bootstrap";

interface IProps {

}

interface iState {

}

class Profile extends React.Component<IProps, iState> {

    constructor(props: IProps) {
        super(props);
    }

    render() {
        const headerStyle = {
            backgroundImage: 'url(\'img/running-bg.jpg\')'
        };

        return (
            <div>
                <header className="masthead" style={headerStyle}>
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10 mx-auto">
                                <div className="site-heading">
                                    <h1>Virtual Race</h1>
                                    <span className="subheading">Pojďte si zaběhat!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <article>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10 mx-auto">
                                <p>Když nemůžeme běhat hromadně, můžeme si aspoň porovnat časy.</p>

                                <h4>Pravidla</h4>

                                <p>Pro každou trať bude vyhlášen týden, ve kterém se "závod" koná. Kdykoli v tomto týdnu
                                si trať zaběhnete a po přihlášení si vyplníte svůj výsledek. Pozor - výsledky je možné vyplnit
                                jenom v "závodním" týdnu.</p>

                                <p>K výsledkům je nutné přiložit odkaz na Strava a/nebo TrainingPeaks aktivitu. Rozhodující
                                je čas, který vyplníte. Soupeři se ale můžou na
                                aktivitu podívat a zhodnotit, jeslti je vyplněný čas reálný. Ideální je, aby aktivita zaznamenala
                                celý závod a nic navíc. Rozklus a výklus můžete dát do samostatných aktivit.</p>

                                <h4>Kategorie</h4>

                                <p>Nebráním se návrhům.</p>

                                <p>
                                    <Table striped bordered hover>
                                        <tr>
                                            <td>M/ŽJ</td>
                                            <td>0-25 let</td>
                                        </tr>
                                        <tr>
                                            <td>M/Ž</td>
                                            <td>25-40 let</td>
                                        </tr>
                                        <tr>
                                            <td>M/ŽM</td>
                                            <td>40-60 let</td>
                                        </tr>
                                        <tr>
                                            <td>M/ŽV</td>
                                            <td>60+ let</td>
                                        </tr>
                                    </Table>
                                </p>

                                <p>Hodně zdaru a ať to běhá :-)</p>



                                <h4>GearTracking</h4>

                                <p>Autor tohoto webu má nasvědomí i iOS aplikaci GearTracking. Pokud chcete například sledovat,
                                    kolik kilometrů máte naběháno ve kterých botách, mrkněte na <a href="https://www.geartracking.net/" target="_blank" rel="noopener noreferrer" >web</a>.
                                </p>
                                <p><a href="https://www.geartracking.net/" target="_blank" rel="noopener noreferrer" ><img alt="GearTracking" src="https://www.geartracking.net/images/banner/home.png"/></a></p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}

export default Profile;
