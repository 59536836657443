import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

// var firebaseConfig = {
//     apiKey: "AIzaSyACOLUgIGaDgY6gnppIB4TwtZ1Bnw1i6Rg",
//     authDomain: "virtualrace-55263.firebaseapp.com",
//     databaseURL: "https://virtualrace-55263.firebaseio.com",
//     projectId: "virtualrace-55263",
//     storageBucket: "virtualrace-55263.appspot.com",
//     messagingSenderId: "886200846029",
//     appId: "1:886200846029:web:db7487941b74d52e090b84",
//     measurementId: "G-BQQ9HBX9FT"
// };

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ]
};

interface IProps {
    signedIn: () => void
}

interface iState {

}

class Login extends React.Component<IProps, iState> {

    uiConfig: any;

    constructor(props: IProps) {
        super(props);

        this.uiConfig = {
            // Popup signin flow rather than redirect flow.
            signInFlow: 'popup',
            // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
            callbacks: {
                signInSuccess: props.signedIn
            },
            // We will display Google and Facebook as auth providers.
            signInOptions: [
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
                firebase.auth.GoogleAuthProvider.PROVIDER_ID
                // firebase.auth.FacebookAuthProvider.PROVIDER_ID
            ],
            credentialHelper: 'none'
        };
    }

    render() {
        return (
            <div className="App">
                <h1>My App</h1>
                <p>Please sign-in:</p>
                <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
            </div>
        );
    }
}

export default Login;
