import React from 'react';
import firebase from 'firebase';
import {Form} from "react-bootstrap";

interface IProps {

}

interface iState {
    name: string
    birthYear: number
    gender: string
}

class Profile extends React.Component<IProps, iState> {

    constructor(props: IProps) {
        super(props);

        this.state = {name: "", birthYear: 0, gender: "M"}

        this.updateProfile = this.updateProfile.bind(this);
        this.nameUpdated = this.nameUpdated.bind(this);
        this.yearUpdated = this.yearUpdated.bind(this);
        this.genderUpdated = this.genderUpdated.bind(this);
    }

    componentDidMount(): void {
        let self = this;

        firebase.auth().onAuthStateChanged(user => {
            if (user === undefined) {
                return;
            }

            let collection = firebase.firestore().collection("users");

            collection.doc(user?.uid).onSnapshot(snapshot => {
                let data = snapshot.data() || {};

                let name = user!!.displayName || "";
                let birthYear = data.birthYear || 0;
                let gender = data.gender || "M";

                self.setState({
                    name: name,
                    birthYear: birthYear,
                    gender: gender
                })
            });
        });
    }

    updateProfile(e: any) {
        e.preventDefault();

        if (firebase.auth().currentUser === undefined) {
            return;
        }

        let collection = firebase.firestore().collection("users");
        collection.doc(firebase.auth().currentUser?.uid).set({
            birthYear: this.state.birthYear,
            gender: this.state.gender
        });

        firebase.auth().currentUser?.updateProfile({
            displayName: this.state.name
        });
    }

    nameUpdated(e: any) {
        this.setState({
            name: e.target.value
        })
    }

    yearUpdated(e: any) {
        this.setState({
            birthYear: e.target.value
        })
    }

    genderUpdated(e: any) {
        this.setState({
            gender: e.target.value
        })
    }

    render() {
        const headerStyle = {
            backgroundImage: 'url(\'img/running-bg.jpg\')'
        };

        return (
            <div>
                <header className="masthead" style={headerStyle}>
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10 mx-auto">
                                <div className="post-heading">
                                    <h1>Profil</h1>
                                    <span className="subheading"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <article>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10 mx-auto">
                                <h3>Profil</h3>
                                <Form onSubmit={this.updateProfile}>
                                    <Form.Group controlId="form.name">
                                        <Form.Label>Jméno:</Form.Label>
                                        <Form.Control type="text" step="1" onChange={this.nameUpdated} value={this.state.name}/>
                                    </Form.Group>
                                    <Form.Group controlId="form.birthYear">
                                        <Form.Label>Rok Narození</Form.Label>
                                        <Form.Control type="number"  onChange={this.yearUpdated} value={this.state.birthYear}/>
                                    </Form.Group>
                                    <Form.Group controlId="form.trainingPeaks">
                                        <Form.Label>Pohlaví</Form.Label>
                                        <Form.Control as="select"  onChange={this.genderUpdated} value={this.state.gender}>
                                            <option value="M">Muž</option>
                                            <option value="W">Žena</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <button>Uložit</button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}

export default Profile;
