import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';
import { BrowserRouter } from 'react-router-dom';

var firebaseConfig = {
    apiKey: "AIzaSyACOLUgIGaDgY6gnppIB4TwtZ1Bnw1i6Rg",
    authDomain: "virtualrace-55263.firebaseapp.com",
    databaseURL: "https://virtualrace-55263.firebaseio.com",
    projectId: "virtualrace-55263",
    storageBucket: "virtualrace-55263.appspot.com",
    messagingSenderId: "886200846029",
    appId: "1:886200846029:web:db7487941b74d52e090b84",
    measurementId: "G-BQQ9HBX9FT"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
