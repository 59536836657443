import React from "react";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import * as firebase from "firebase";
import {LinkContainer} from 'react-router-bootstrap';

interface IProps {
    logout: () => void
    isLoggedIn: boolean
}

interface IState {
    name: string,
    description: string
    stravaSegmentId: string
    races: Race[]
}

interface Race {
    id: string
    name: string
}


class Menu extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {name: "undef", description: "undef", stravaSegmentId: "0", races: []};
    }

    componentDidMount(): void {
        let self = this;
        let collection = firebase.firestore().collection("races");

        collection.onSnapshot(snapshot => {
            let allRaces: Race[] = [];

            snapshot.forEach( race => {
                let data = race.data() || {};
                let name = data.name || "Race Name";

                allRaces.push({
                    id: race.id,
                    name: name
                });
            });

            self.setState({
                races: allRaces
            })
        });


    }

    render() {
        return (
            <Navbar bg="light" expand="lg">
                <LinkContainer to="/">
                    <Navbar.Brand>Virtual Race</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown title="Závody" id="basic-nav-dropdown">
                            {this.state.races.map((race) =>
                                <LinkContainer to={"/race/" + race.id}>
                                    <NavDropdown.Item>{race.name}</NavDropdown.Item>
                                </LinkContainer>
                            )}
                            {/*<NavDropdown.Divider />*/}
                            {/*<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
                        </NavDropdown>
                        { this.props.isLoggedIn &&
                            <LinkContainer to="/profile">
                                <Nav.Link>Profil</Nav.Link>
                            </LinkContainer>
                        }

                        { this.props.isLoggedIn ?
                            <Nav.Link href="#" onClick={this.props.logout}>Odhlásit</Nav.Link>
                            :
                            <LinkContainer to="/login">
                                <Nav.Link href="#login">Login</Nav.Link>
                            </LinkContainer>
                        }
                            </Nav>
                            </Navbar.Collapse>
                            </Navbar>
                            );
                        }
                        }

export default Menu;