import React from "react";
import * as firebase from "firebase";
import {Form, Table} from "react-bootstrap";

interface IProps {
    raceId: string
    isLoggedIn: boolean
}

interface IState {
    name: string,
    caption: string
    description: string
    stravaSegmentId: string
    editable: boolean

    form: {
        time: string,
        stravaLink: string,
        trainingPeaksLink: string
        garminLink: string
    }

    results: Result[]

    myResult?: Result,
    editMyResult: boolean
}

interface Result {
    number: number
    name: string,
    timeInSeconds: number,
    stravaLink: string,
    trainingPeaksLink: string
    garminLink: string
    birthYear: number
    gender: string
    myResult: boolean
}

class RaceDetail extends React.Component<IProps, IState> {

    firebaseRaceId?: string;

    constructor(props: IProps) {
        super(props);

        this.firebaseRaceId = undefined;

        this.state = {name: "undef", caption: "", description: "undef", stravaSegmentId: "0", editable: false,
                form: {
                    time: "00:00:00", stravaLink: "", trainingPeaksLink: "", garminLink: ""
                },
                results: [],
                editMyResult: false
            };

        this.setupFirebase = this.setupFirebase.bind(this);
        this.updateResults = this.updateResults.bind(this);
        this.timeUpdated   = this.timeUpdated.bind(this);
        this.stravaUpdated = this.stravaUpdated.bind(this);
        this.trainingPeaksUpdated = this.trainingPeaksUpdated.bind(this);
        this.garminUpdated = this.garminUpdated.bind(this);
        this.editMyResult = this.editMyResult.bind(this);
    }

    componentDidMount(): void {
        this.setupFirebase();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        this.setupFirebase();
    }

    private setupFirebase() {
        if (this.props.raceId === this.firebaseRaceId) {
            return;
        }

        let self = this;
        let collection = firebase.firestore().collection("races");

        collection.doc(this.props.raceId).onSnapshot(snapshot => {
            let data = snapshot.data() || {};

            let name = data.name || "Race Name";
            let caption = data.caption || "";
            let desc = data.description || "Race description";
            let stravaSegment = data.stravaSegment || "0";
            self.setState({
                name: name,
                caption: caption,
                description: desc,
                stravaSegmentId: stravaSegment,
                editable: data.editable
            });
        });

        collection.doc(this.props.raceId).collection("results").orderBy("timeInSeconds").onSnapshot(results => {
            let allResults: Result[] = [];

            var number = 1;
            results.forEach( result => {
                let data = result.data() || {};

                allResults.push({
                    number: number++,
                    name: data.name,
                    timeInSeconds: data.timeInSeconds,
                    stravaLink: data.stravaLink,
                    trainingPeaksLink: data.trainingPeaksLink,
                    garminLink: data.garminLink,
                    birthYear: data.birthYear,
                    gender: data.gender,
                    myResult: result.id === firebase.auth().currentUser?.uid
                });

                if (result.id === firebase.auth().currentUser?.uid) {
                    self.setState({
                        myResult: {
                            number: number,
                            name: data.name,
                            timeInSeconds: data.timeInSeconds,
                            stravaLink: data.stravaLink,
                            trainingPeaksLink: data.trainingPeaksLink,
                            garminLink: data.garminLink,
                            birthYear: data.birthYear,
                            gender: data.gender,
                            myResult: true
                        }
                    });
                }
            });

            self.setState({
                results: allResults
            });
        });

        this.firebaseRaceId = this.props.raceId;
    }

    updateResults(e: any) {
        e.preventDefault();

        var hms = this.state.form.time;   // your input string
        var a = hms.split(':'); // split it at the colons
        var timeInSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

        if (this.state.form.stravaLink.indexOf("strava") < 0 &&
            this.state.form.trainingPeaksLink.indexOf("tpks") < 0 &&
            this.state.form.garminLink.indexOf("garmin") < 0) {

            alert("Alespoň jeden z odkazů na Stravu, TrainingPeaks nebo Garmmin musí být vyplněn");
            return;
        }

        if ( ! (timeInSeconds > 0)) {
            alert("Je nutné vyplnit dosažený čas");
            return;
        }

        let collection = firebase.firestore().collection("races");
        let newDocument = collection.doc(this.props.raceId).collection("results").doc(firebase.auth().currentUser?.uid);

        firebase.firestore().collection("users").doc(firebase.auth().currentUser?.uid).get().then(user => {
            let userData = user.data() || {}
            newDocument.set({
                name: firebase.auth().currentUser?.displayName,
                timeInSeconds: timeInSeconds,
                stravaLink: this.state.form.stravaLink,
                trainingPeaksLink: this.state.form.trainingPeaksLink,
                garminLink: this.state.form.garminLink,
                birthYear: userData.birthYear,
                gender: userData.gender
            }, {merge: true});
        });

        this.setState({
            editMyResult: false
        });
    }

    timeUpdated(e: any) {
        var time = e.target.value;

        if ((time.match(/:/g) || []).length < 2) {
            time += ":00";
        }

        this.setState({
            form: {
                time: time,
                stravaLink: this.state.form.stravaLink,
                trainingPeaksLink: this.state.form.trainingPeaksLink,
                garminLink: this.state.form.garminLink
                }
        });
    }

    stravaUpdated(e: any) {
        let stravaLink = e.target.value;

        this.setState({
            form: {
                time: this.state.form.time,
                stravaLink: stravaLink,
                trainingPeaksLink: this.state.form.trainingPeaksLink,
                garminLink: this.state.form.garminLink
            }
        });
    }

    trainingPeaksUpdated(e: any) {
        let trainingPeaksLink = e.target.value;

        this.setState({
            form: {
                time: this.state.form.time,
                stravaLink: this.state.form.stravaLink,
                trainingPeaksLink: trainingPeaksLink,
                garminLink: this.state.form.garminLink
            }
        });
    }

    garminUpdated(e: any) {
        let garminLink = e.target.value;

        this.setState({
            form: {
                time: this.state.form.time,
                stravaLink: this.state.form.stravaLink,
                trainingPeaksLink: this.state.form.trainingPeaksLink,
                garminLink: garminLink
            }
        });
    }

    editMyResult() {
        let time =  this.state.myResult!!.timeInSeconds;
        let stravaLink = this.state.myResult!!.stravaLink;
        let trainingPeaksLink = this.state.myResult!!.trainingPeaksLink;

        this.setState({
            form: {
                time: this.formatTime(time),
                stravaLink: stravaLink,
                trainingPeaksLink: trainingPeaksLink,
                garminLink: this.state.form.garminLink
            },
            editMyResult: true
        });
    }

    formatTime(time: number): string {
        let pad = function(num: number, size: number) { return ('000' + num).slice(size * -1); };

        let hours = Math.floor(time / 60 / 60);
        let minutes = Math.floor(time / 60) % 60;
        let seconds = Math.floor(time - minutes * 60);

        return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2)
    }

    formatCategory(result: Result): string {
        let czechGender = result.gender === "M" ? "M" : "Ž";
        let age = 2020 - result.birthYear;

        var catString = "";

        if (age < 25) {
            catString = "J";
        }

        if (age >= 40 && age < 60) {
            catString = "M";
        }

        if (age >= 60) {
            catString = "V";
        }

        return czechGender + catString;
    }

    render() {
        const headerStyle = {
            backgroundImage: 'url(\'/img/running-bg.jpg\')'
        };

        return (
            <div>
                <header className="masthead" style={headerStyle}>
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10 mx-auto">
                                <div className="post-heading">
                                    <h1>{this.state.name}</h1>
                                    <span className="subheading">{this.state.caption}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <article>
                    <div className="container">

                        {this.props.isLoggedIn && this.state.editable ?
                            this.state.myResult === undefined || this.state.editMyResult ?
                                <div className="row">
                                    <div className="col-lg-8 col-md-10 mx-auto">
                                        <h3>Můj výsledek</h3>
                                        <Form onSubmit={this.updateResults}>
                                            <Form.Group controlId="form.time">
                                                <Form.Label>Čas:</Form.Label>
                                                <Form.Control type="time" step="1" onChange={this.timeUpdated} value={this.state.form.time}/>
                                            </Form.Group>
                                            <Form.Group controlId="form.strava">
                                                <Form.Label>Strava Aktivita</Form.Label>
                                                <Form.Control type="text"  onChange={this.stravaUpdated} value={this.state.form.stravaLink}/>
                                            </Form.Group>
                                            <Form.Group controlId="form.trainingPeaks">
                                                <Form.Label>TrainingPeaks Aktivita</Form.Label>
                                                <Form.Control type="text"  onChange={this.trainingPeaksUpdated} value={this.state.form.trainingPeaksLink}/>
                                            </Form.Group>
                                            <Form.Group controlId="form.garmin">
                                                <Form.Label>Garmin Aktivita</Form.Label>
                                                <Form.Control type="text"  onChange={this.garminUpdated} value={this.state.form.garminLink}/>
                                            </Form.Group>
                                            <button>Uložit</button>
                                        </Form>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-lg-8 col-md-10 mx-auto">
                                        <button onClick={this.editMyResult}>Upravit můj výsledek</button>
                                    </div>
                                </div>
                            : this.state.editable ?
                                <div className="row">
                                    <div className="col-lg-8 col-md-10 mx-auto">
                                        <h4>Pro zadání výsledků musíte být přihlášeni</h4>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-lg-8 col-md-10 mx-auto">
                                        <h4>Zadávání výsledků pro tento závod je uzavřeno</h4>
                                    </div>
                                </div>
                        }

                        <div className="row">
                            <div className="col-lg-8 col-md-10 mx-auto" dangerouslySetInnerHTML={{ __html: this.state.description}}>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-12 mx-auto">
                                <p>Výsledky:</p>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Jméno</th>
                                        <th>Kategorie</th>
                                        <th>Čas</th>
                                        <th>Strava Aktivita</th>
                                        <th>TrainingPeaks Aktivita</th>
                                        <th>Garmin Aktivita</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.results.map((result) =>
                                            <tr className={result.myResult ? "my-result": ""}>
                                                <td>{result.number}</td>
                                                <td>{result.name}</td>
                                                <td>{this.formatCategory(result)}</td>
                                                <td>{this.formatTime(result.timeInSeconds)}</td>
                                                <td>{result.stravaLink && <a href={result.stravaLink} target="_blank" rel="noopener noreferrer">Strava</a> }</td>
                                                <td>{result.trainingPeaksLink && <a href={result.trainingPeaksLink} target="_blank" rel="noopener noreferrer">Training Peaks</a> }</td>
                                                <td>{result.garminLink && <a href={result.garminLink} target="_blank" rel="noopener noreferrer">Garmin Connect</a> }</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
    );
    }
}

export default RaceDetail;
